import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';
import 'firebase/functions'

if (firebase.apps && !firebase.apps.length) {
  firebase.initializeApp(
    {
      apiKey: "AIzaSyBBqUTreiIJwSIwbD2m8_D7-2B2Sx4UFPI",
      authDomain: "misawaya-55b4d.firebaseapp.com",
      databaseURL: "https://misawaya-55b4d.firebaseio.com",
      projectId: "misawaya-55b4d",
      storageBucket: "misawaya-55b4d.appspot.com",
      messagingSenderId: "1097177214084",
      appId: "1:1097177214084:web:4661a12f6862e08f48a6d1"
    }
  )
}

// Functions Local Test
// const functions = firebase.functions();
// functions.useFunctionsEmulator('http://localhost:5001')

export default firebase
